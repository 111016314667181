/* Import Types */
import { VirtualReferenceCollection } from "app/types/VirtualReferenceCollection";

/* Import Sources */
import References from 'sources/data/references.json';


const GetVirtualReferenceCollection = async (virtualReferenceCollectionID: string) => {
    let virtualReferenceCollection: VirtualReferenceCollection = {} as VirtualReferenceCollection;
    const references = References;

    if (virtualReferenceCollectionID) {
        try {
            const data = references[virtualReferenceCollectionID as keyof typeof references] as VirtualReferenceCollection;

            /* Set Reference Collections */
            virtualReferenceCollection = data;
        } catch (error) {
            console.warn(error);
        }
    }

    return virtualReferenceCollection;
}

export default GetVirtualReferenceCollection;