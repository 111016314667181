/* Import Sources */
import ReferenceNames from 'sources/data/referenceNames.json';


const GetVirtualReferenceCollections = async () => {
    let virtualReferenceCollectionsList: string[] = [];
    const referenceNames = ReferenceNames;

    try {
        /* Set Reference Collections */
        virtualReferenceCollectionsList = referenceNames.ids;
    } catch (error) {
        console.warn(error);
    }

    return virtualReferenceCollectionsList;
}

export default GetVirtualReferenceCollections;